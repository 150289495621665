import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'

const { qbert } = ApiClient.getInstance()

export const ingressActions = ActionsSet.make<DataKeys.Ingresses>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.Ingresses],
  cacheKey: DataKeys.Ingresses,
})

export const listIngresses = ingressActions.add(
  new ListAction<DataKeys.Ingresses, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Ingresses', params)
    return qbert.getIngresses(params.clusterId)
  }),
)
