import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { getRules } from './helpers'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const ingressesSelector = createSharedSelector(
  getDataSelector<DataKeys.Ingresses>(DataKeys.Ingresses),
  (rawIngresses) => {
    return rawIngresses.map((ingress) => {
      const defaultBackend = ingress.spec?.defaultBackend?.service
        ? {
            name: ingress.spec?.defaultBackend?.service?.name,
            portName: ingress.spec?.defaultBackend?.service?.port?.name,
            port: ingress.spec?.defaultBackend?.service?.port?.number,
          }
        : ingress.spec?.defaultBackend?.resource
      return {
        ...ingress,
        id: ingress.metadata?.uid,
        name: ingress.metadata?.name,
        namespace: ingress.metadata?.namespace,
        creationTimestamp: ingress.metadata?.creationTimestamp,
        defaultBackend,
        rules: getRules(ingress),
      }
    })
  },
)
