import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import EntityYamlPage from '../../../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { IPersistentVolumeClaimDetailsPageTabs } from '../model'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listPersistentVolumeClaims, updatePersistentVolumeClaim } from '../new-actions'
import { persistentVolumeClaimsSelector } from '../selectors'
import Overview from './Overview'
import DeletePersistentVolumeClaimDialog from '../DeletePersistentVolumeClaimDialog'
import OverviewActions from 'core/elements/overview/OverviewActions'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'

const { qbert } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeletePersistentVolumeClaimDialog,
    },
  ],
}

export default function PersistentVolumeClaimDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtStorage' : 'storage'
  const { id, clusterId } = match.params
  const { globalParams: params } = useGlobalParams(useParams, {})
  const { loading } = useListAction(listPersistentVolumeClaims, { params })
  const persistentVolumeClaims = useSelectorWithParams(persistentVolumeClaimsSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const persistentVolumeClaim = useMemo(
    () => persistentVolumeClaims.find((plugin) => plugin.id === id),
    [id, persistentVolumeClaims],
  )
  const { update, updating } = useUpdateAction(updatePersistentVolumeClaim)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: persistentVolumeClaim?.clusterName || clusterId,
        id: persistentVolumeClaim?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [persistentVolumeClaim?.clusterName, persistentVolumeClaim?.name, id, clusterId])

  const getPersistentVolumeClaimYaml = useCallback(async () => {
    if (isEmpty(persistentVolumeClaim)) return undefined
    return qbert.getPersistentVolumeClaim(
      persistentVolumeClaim.clusterId,
      persistentVolumeClaim.namespace,
      persistentVolumeClaim.name,
    )
  }, [persistentVolumeClaim])

  const handlePersistentVolumeClaimUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Persistent Volume Claim" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={persistentVolumeClaim} />
      </div>
      <Tabs route={routes[routePath].persistentVolumeClaims.details}>
        <Tab label="Overview" value={IPersistentVolumeClaimDetailsPageTabs.Overview}>
          <Overview persistentVolumeClaim={persistentVolumeClaim} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IPersistentVolumeClaimDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Persistent Volume Claim"
            entityName={persistentVolumeClaim?.name}
            getYamlFn={getPersistentVolumeClaimYaml}
            handleUpdate={handlePersistentVolumeClaimUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
