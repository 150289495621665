import React from 'react'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteNetwork } from './actions'
import useListAction from 'core/hooks/useListAction'
import { listPods } from 'k8s/components/pods/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { podsSelector } from 'k8s/components/pods/selectors'
import { useMemo, useState } from 'react'
import Grid from 'core/elements/grid/Grid'
import Alert from 'core/components/Alert'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import usePluginRouter from 'core/hooks/usePluginRouter'

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
]

export default function DeleteNetworkAttachmentDefinitionsDialog({
  rows: [network],
  onClose,
  onSuccess,
}) {
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const { history } = useReactRouter()
  const { update: deleteNet, updating: deletingNetwork } = useUpdateAction(deleteNetwork)

  const { loading: loadingPods } = useListAction(listPods, {
    params: {
      clusterId: network?.clusterId,
      namespace: network?.namespace,
    },
  })
  const pods = useSelectorWithParams(podsSelector, {
    clusterId: network?.clusterId,
    useGlobalParams: false,
  })

  const networkPods = useMemo(() => {
    return pods.reduce((accum, pod) => {
      const annotations = pod.annotations || {}

      Object.entries(annotations).forEach(([key, value]) => {
        if (key === 'k8s.v1.cni.cncf.io/networks') {
          const networksObj = JSON.parse(value)
          if (network.name === networksObj[0].name) {
            accum.push({ name: pod.name, type: 'Pod' })
          }
        }
      })
      return accum
    }, [])
  }, [pods])

  const [error, setError] = useState(false)

  const handleDelete = async () => {
    if (networkPods.length > 0) {
      setError(true)
      return
    }
    const { success } = await deleteNet(network)
    if (success) onClose()
    history.push(routes[routePath].network.path())
  }

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      title={`Remove Network Attachment Definition "${network?.name}" ?`}
      footer={
        <>
          <Button onClick={onClose} variant="secondary" disabled={deletingNetwork}>
            {error ? 'Close' : 'Cancel'}
          </Button>
          {!error && (
            <Button onClick={handleDelete} variant="primary" loading={deletingNetwork}>
              Remove
            </Button>
          )}
        </>
      }
    >
      {error ? (
        <>
          <Alert
            variant="error"
            title={'Remove Error'}
            message={'Network Attachment Definition that is already attached cannot be removed.'}
          />
          <Grid
            uniqueIdentifier="name"
            data={networkPods}
            columns={columns}
            loading={loadingPods}
            disableToolbar
            compact
          />
        </>
      ) : (
        <Text variant="body1" component="div">
          Your data will be lost since this action cannot be undone.
        </Text>
      )}
    </Modal>
  )
}
