import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import RadioSelectableCard from 'k8s/components/common/RadioSelectableCard'
import BasicSettingsParamFields from './BasicSettingsParamFields'
import YamlParamFields from './YamlParamFields'
import SelectorParamFields from './SelectorParamFields'
import HostConfigurationsParamFields, {
  defaultInterfaceConfig,
  defaultOvsConfig,
  defaultSriovConfig,
} from './HostConfigurationsParamFields'
import { createHostNetworkTemplate } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import jsYaml from 'js-yaml'
import usePluginRouter from 'core/hooks/usePluginRouter'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 32,
  },
  radioText: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  radioCards: {
    display: 'grid',
    gap: 16,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
}))

export interface InterfaceConfigs {
  name: string
  mtu?: string
  vlanIds?: string[]
  ipv4Addresses?: string[]
  ipv6Addresses?: string[]
}

export interface OvsConfigs {
  bridgeName: string
  nodeInterface: string
  enableDpdk?: boolean
  enableParams?: boolean
  mtuRequest?: string
  bondMode?: string
  lacp?: string
}

export interface SriovConfigs {
  deviceSelector: string
  pfName?: string
  vendorId?: string
  deviceId?: string
  pciAddress?: string
  numVfs: number
  vfDriver: string
}

export default function AddHostConfigurationModal({ addRoute }) {
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
  const classes = useStyles()

  const defaultParams = {
    method: 'form',
    configType: null,
    name: '',
    clusterId: null,
    namespace: null,
    yaml: '',
    selectors: {},
    interfaceConfigs: [defaultInterfaceConfig],
    ovsConfigs: [defaultOvsConfig],
    sriovConfigs: [defaultSriovConfig],
  }

  const { params, updateParams, setParams } = useParams<{
    method: string
    configType: string
    name: string
    clusterId: string
    namespace: string
    yaml?: string
    selectors?: any
    interfaceConfigs?: InterfaceConfigs[]
    ovsConfigs?: OvsConfigs[]
    sriovConfigs?: SriovConfigs[]
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createHostNetworkTemplate)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes[routePath].hostConfigurations.path())
  }

  const submitForm = useCallback(async () => {
    if (params?.method === 'yaml') {
      const body = jsYaml.load(params?.yaml)
      const { success } = await update({
        clusterId: params?.clusterId,
        namespace: params?.namespace,
        body,
      })
      if (success) handleClose()
    }

    const interfaceConfig = params?.interfaceConfigs
      ?.filter((config) => !!config?.name)
      .map((config) => {
        return {
          name: config?.name,
          mtu: config?.mtu ? parseInt(config.mtu) : undefined,
          ipv4: config?.ipv4Addresses?.length ? { address: config.ipv4Addresses } : undefined,
          ipv6: config?.ipv6Addresses?.length ? { address: config.ipv6Addresses } : undefined,
          vlan: config?.vlanIds?.length
            ? config.vlanIds.map((id) => ({ id: parseInt(id) }))
            : undefined,
        }
      })
    const ovsConfig = params?.ovsConfigs
      ?.filter((config) => !!config?.bridgeName)
      .map((config) => {
        return {
          bridgeName: config?.bridgeName,
          nodeInterface: config?.nodeInterface,
          dpdk: !!config?.enableDpdk,
          params: {
            mtu_request: config?.mtuRequest ? config.mtuRequest : undefined,
            bond_mode: config?.bondMode ? config.bondMode : undefined,
            lacp: config?.lacp ? config.lacp : undefined,
          },
        }
      })
    const sriovConfig = params?.sriovConfigs
      ?.filter((config) => !!config?.numVfs)
      .map((config) => {
        return {
          pfName: config?.deviceSelector === 'pfName' ? config?.pfName : undefined,
          vendorId: config?.deviceSelector === 'vendor' ? config?.vendorId : undefined,
          deviceId: config?.deviceSelector === 'vendor' ? config?.deviceId : undefined,
          pciAddr: config?.deviceSelector === 'pciAddress' ? config?.pciAddress : undefined,
          numVfs: config?.numVfs,
          vfDriver: config?.vfDriver,
        }
      })

    const body = {
      apiVersion: 'plumber.k8s.pf9.io/v1',
      kind: 'HostNetworkTemplate',
      metadata: {
        name: params?.name,
      },
      spec: {
        nodeSelector: params?.selectors,
        interfaceConfig: interfaceConfig?.length ? interfaceConfig : undefined,
        ovsConfig: ovsConfig?.length ? ovsConfig : undefined,
        sriovConfig: sriovConfig?.length ? sriovConfig : undefined,
      },
    }

    const { success } = await update({
      clusterId: params?.clusterId,
      namespace: params?.namespace,
      body,
    })
    if (success) handleClose()
  }, [params, handleClose])

  return (
    <ModalForm
      route={addRoute}
      title={`Add Host Configuration`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Add Host Configuration`}
      maxWidth={528}
    >
      <div className={classes.container}>
        <div className={classes.radioCards}>
          <Text variant="caption2" className={classes.radioText}>
            Select one of the below methods
          </Text>
          <RadioSelectableCard
            onClick={() => updateParams({ method: 'form' })}
            label="Fill in the form"
            active={params?.method === 'form'}
            subtitle=""
          />
          <RadioSelectableCard
            onClick={() => updateParams({ method: 'yaml' })}
            label="Upload or paste YAML"
            active={params?.method === 'yaml'}
            subtitle=""
          />
        </div>
        <hr className={classes.divider} />
        {params?.method === 'yaml' && (
          <YamlParamFields params={params} updateParams={updateParams} />
        )}
        {params?.method === 'form' && (
          <div className={classes.fields}>
            <BasicSettingsParamFields params={params} updateParams={updateParams} />
            <SelectorParamFields method="add" params={params} updateParams={updateParams} />
            <HostConfigurationsParamFields
              method="add"
              params={params}
              updateParams={updateParams}
            />
          </div>
        )}
      </div>
    </ModalForm>
  )
}
