import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import { createDataVolume } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import jsYaml from 'js-yaml'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import moize from 'moize'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 24,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
  limitWidth: {
    // Max width possible for this w/o causing scrollbar
    // Assumes dialog with max width of 528px
    maxWidth: 407,
  },
}))

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body?.kind === 'DataVolume'
    } catch (err) {
      return true
    }
  }, 'Yaml kind must be DataVolume'),
]

export default function AddDataVolumeModal({ addRoute }) {
  const { history } = useReactRouter()
  const classes = useStyles()

  const defaultParams = {
    clusterId: '',
    namespace: '',
    yaml: '',
  }

  const { params, updateParams, setParams } = useParams<{
    clusterId: string
    namespace: string
    yaml: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createDataVolume)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.kubevirtStorage.dataVolumes.list.path())
  }

  const submitForm = useCallback(async () => {
    const { success } = await update({
      clusterId: params?.clusterId,
      namespace: params?.namespace,
      yaml: params?.yaml,
    })
    if (success) handleClose()
  }, [params, handleClose])

  return (
    <ModalForm
      route={addRoute}
      title={`Add Data Volume`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Add Data Volume`}
      maxWidth={528}
    >
      <div className={classes.container}>
        <PicklistField
          DropdownComponent={ClusterPicklist}
          id="clusterId"
          label="Cluster"
          onChange={(value) => updateParams({ clusterId: value })}
          value={params.clusterId}
          showAll={false}
          setInitialCluster
          required
        />
        <PicklistField
          DropdownComponent={NamespacePicklist}
          disabled={!params.clusterId}
          id="namespace"
          label="Namespace"
          onChange={(value) => updateParams({ namespace: value })}
          clusterId={params.clusterId}
          value={params.namespace}
          showAll={false}
          setInitialNamespace
          required
        />
        <hr className={classes.divider} />
        <div className={classes.fields}>
          <Text variant="caption1">Upload Data Volume YAML</Text>
          <TextFileDrop
            onChange={(value) => updateParams({ yaml: value })}
            fileTypes={['.yaml', '.yml']}
          />
          <CodeMirror
            className={classes.limitWidth}
            label="YAML"
            id="yaml"
            validations={codeMirrorValidations}
            onChange={(value) => updateParams({ yaml: value })}
            value={params?.yaml}
            options={codeMirrorOptions}
          />
        </div>
      </div>
    </ModalForm>
  )
}
