import React, { useMemo, useCallback } from 'react'
import { emptyObj } from 'utils/fp'
import useReactRouter from 'use-react-router'
import { propEq } from 'ramda'
import useParams from 'core/hooks/useParams'
import UserMultiSelect from 'k8s/components/common/UserMultiSelect'
import GroupMultiSelect from 'k8s/components/common/GroupMultiSelect'
import PresetField from 'core/components/PresetField'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listClusterRoleBindings, updateClusterRoleBinding } from './new-actions'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { clusterRoleBindingsSelector } from './selectors'
import { IRbacClusterRoleBindingSelector } from './model'
import ModalForm from 'core/elements/modal/ModalForm'
import usePluginRouter from 'core/hooks/usePluginRouter'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

const defaultParams = {
  users: [] as string[],
  groups: [] as string[],
}

export default function UpdateClusterRoleBindingPage() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const clusterRoleBindingId = match.params.id
  const clusterId = match.params.clusterId
  const { currentPluginId } = usePluginRouter()

  const { loading } = useListAction(listClusterRoleBindings, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const clusterRoleBindings = useSelectorWithParams(clusterRoleBindingsSelector, { clusterId })

  const clusterRoleBinding = useMemo(
    () =>
      clusterRoleBindings.find(propEq('id', clusterRoleBindingId)) ||
      (emptyObj as IRbacClusterRoleBindingSelector),
    [clusterRoleBindings, clusterRoleBindingId],
  )
  const { getParamsUpdater } = useParams(defaultParams)

  const { update, updating, reset, error: errorUpdatingClusterRoleBinding } = useUpdateAction(
    updateClusterRoleBinding,
  )

  const submitForm = useCallback(
    async (params) => {
      const { success } = await update({ ...clusterRoleBinding, ...params })
      if (success) handleClose()
    },
    [clusterRoleBinding],
  )

  const handleClose = () => {
    reset()
    history.push(routes.rbac.clusterRoleBindings.path({ plugin: currentPluginId }))
  }

  return (
    <>
      <DocumentMeta title="Update Cluster Role Binding" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.rbac.editClusterRoleBindings}
        title="Edit Cluster Role Binding"
        onSubmit={submitForm}
        onClose={handleClose}
        loading={loading}
        submitting={updating}
        error={errorUpdatingClusterRoleBinding}
        loadingMessage={loading ? 'Loading cluster role binding...' : 'Submitting form...'}
        submitTitle="Update Cluster Role Binding"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <PresetField label="Name" value={clusterRoleBinding.name} />
            <PresetField label="Cluster" value={clusterRoleBinding.clusterName} />
            {clusterRoleBinding.roleRef && (
              <PresetField label="Role" value={clusterRoleBinding.roleRef.name} />
            )}
          </FormFieldSection>
          {clusterRoleBinding.users && (
            <FormFieldSection title="Assign Users to this Cluster Binding">
              <UserMultiSelect
                id="users"
                tooltip="Select users to assign this role"
                onChange={getParamsUpdater('users')}
                initialValue={clusterRoleBinding.users}
              />
            </FormFieldSection>
          )}
          {clusterRoleBinding.groups && (
            <FormFieldSection title="Assign Groups to this Cluster Binding" step={2}>
              <GroupMultiSelect
                id="groups"
                tooltip="Select groups to assign this role"
                onChange={getParamsUpdater('groups')}
                initialValue={clusterRoleBinding.groups}
              />
            </FormFieldSection>
          )}
        </>
      </ModalForm>
    </>
  )
}
