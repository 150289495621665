import React, { useState } from 'react'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import namespaceActions from '../namespaces/actions'
import useDataUpdater from 'core/hooks/useDataUpdater'
import { namespaceValidator } from 'core/utils/fieldValidators'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import ModalForm from 'core/elements/modal/ModalForm'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useListAction from 'core/hooks/useListAction'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    color: theme.components.graph.success,
    marginRight: theme.spacing(1),
  },
  fail: {
    color: theme.components.graph.error,
    marginRight: theme.spacing(1),
  },
}))

const result = {
  success: {
    icon: 'check',
    title: 'Success',
    getText: (
      name,
    ) => `The namespace ${name} was successfuly created. It can now be utilized for your app
    deployment.`,
    buttonLabel: 'Continue',
  },
  fail: {
    icon: 'exclamation-circle',
    title: 'Namespace creation failed',
    getText: () => 'The creation of the namespace you specified was unsuccessful.',
    buttonLabel: 'Ok',
  },
}

enum Status {
  Success = 'success',
  Fail = 'fail',
}

const AddNewNamespaceDialog = ({ clusterId, onClose }) => {
  const classes = useStyles()
  const { loading: loadingClusters } = useListAction(listClusters, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const clusters = useSelectorWithParams(clustersSelector, { clusterId })
  const cluster = clusters.find((cluster) => cluster.uuid === clusterId)
  const [createNamespace, creatingNamespace] = useDataUpdater(namespaceActions.create)
  const [showResult, setShowResult] = useState(false)
  const [status, setStatus] = useState<Status>(Status.Fail)
  const [namespaceName, setNamespaceName] = useState('')

  const handleSubmit = async ({ name }) => {
    const [success] = await createNamespace({ clusterId, name })
    setStatus(success ? Status.Success : Status.Fail)
    setShowResult(true)
    setNamespaceName(name)
  }

  return (
    <ModalForm
      open
      panel="dialog"
      onClose={onClose}
      loading={loadingClusters || creatingNamespace}
      submitTitle={showResult ? result[status].buttonLabel : undefined}
      onSubmit={!showResult ? handleSubmit : undefined}
      title={
        showResult ? (
          <Text variant="subtitle1" component="span">
            <FontAwesomeIcon className={classes[status]}>{result[status].icon}</FontAwesomeIcon>
            {result[status].title}
          </Text>
        ) : (
          'Create New Namespace'
        )
      }
    >
      {!showResult ? (
        <>
          <Text variant="body2">Create a new namespace for cluster {cluster?.name}.</Text>
          <TextField id="name" label="Namespace" required validations={[namespaceValidator]} />
        </>
      ) : (
        <Text variant="body2">{result[status].getText(namespaceName)}</Text>
      )}
    </ModalForm>
  )
}

export default AddNewNamespaceDialog
