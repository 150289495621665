import React, { useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useParams from 'core/hooks/useParams'
import { isNilOrEmpty, switchCase } from 'utils/fp'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { keys, omit, without } from 'ramda'
import {
  updateVirtualMachine,
  updateVirtualMachineInstance,
} from 'app/plugins/kubevirt/components/virtual-machines/new-actions'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { updatePod } from 'k8s/components/pods/new-actions'
import { updateDataVolume } from 'k8s/components/storage/data-volumes/new-actions'
import { updatePersistentVolume } from 'k8s/components/storage/persistent-volume/new-actions'
import { updateConfigMap } from 'k8s/components/config-maps/new-actions'
import { updateDeployment } from 'k8s/components/deployments/new-actions'
import { updateService } from 'k8s/components/services/new-actions'
import { updateStatefulSet } from 'k8s/components/stateful-sets/actions'
import { updateReplicaSet } from 'k8s/components/replica-sets/actions'
import { updateDaemonSet } from 'k8s/components/daemon-sets/actions'
import { updateJob } from 'k8s/components/cronjobs/actions'
import { updateSecret } from 'k8s/components/configuration/secrets/actions'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '24px 40px',
    display: 'grid',
    gap: 32,
  },
  currentAnnotations: {
    display: 'grid',
    gap: 24,
  },
  annotationRow: {
    display: 'flex',
    alignItems: 'center',
  },
  annotationKey: {
    flexGrow: 1,
  },
  annotationValue: {
    flexGrow: 1,
  },
  annotationDash: {
    fontSize: 24,
    margin: `0px 8px`,
    position: 'relative',
    top: 10,
  },
  minusIcon: {
    color: theme.components.badge.primary.color,
    position: 'relative',
    top: 14,
    marginLeft: 24,
  },
  addAnnotations: {
    display: 'grid',
    gap: 24,
  },
  addAnnotation: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  plusIcon: {
    color: theme.components.badge.primary.color,
  },
  noneText: {
    margin: theme.spacing(2),
    color: theme.components.card.passiveText,
  },
}))

export default function EditEntityAnnotationsModal({ resourceType, entity, open, onClose }) {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const defaultParams = {
    resourceType,
    currentIndexes: [],
    annotationKeys: {},
    annotationValues: {},
    newIndexes: [],
    newAnnotationKeys: {},
    newAnnotationValues: {},
  }

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    resourceType: string
    currentIndexes?: number[]
    annotationKeys?: { [key: string]: string }
    annotationValues?: { [key: string]: string | number }
    newIndexes?: number[]
    newAnnotationKeys?: { [key: string]: string }
    newAnnotationValues?: { [key: string]: string | number }
  }>(defaultParams)

  const annotations = entity?.annotations || entity?.metadata?.annotations

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    if (annotations) {
      const annotationKeys = keys(annotations) || []
      const annotationDataMap = annotationKeys.reduce(
        (accum, key, index) => ({
          indexes: [...accum.indexes, index],
          keys: {
            ...accum.keys,
            [index]: key,
          },
          values: {
            ...accum.values,
            [index]: annotations?.[key],
          },
        }),
        { indexes: [], keys: {}, values: {} },
      )
      updateParams({
        currentIndexes: annotationDataMap.indexes,
        annotationKeys: annotationDataMap.keys,
        annotationValues: annotationDataMap.values,
      })
      setLoaded(true)
    }
  }, [entity, loaded])

  const updateFn = switchCase({
    pod: updatePod,
    deployment: updateDeployment,
    service: updateService,
    statefulSet: updateStatefulSet,
    replicaSet: updateReplicaSet,
    daemonSet: updateDaemonSet,
    job: updateJob,
    vm: updateVirtualMachine,
    vmi: updateVirtualMachineInstance,
    dataVolume: updateDataVolume,
    persistentVolume: updatePersistentVolume,
    configMap: updateConfigMap,
    secret: updateSecret,
  })(params.resourceType)

  const { update, updating, error, reset } = useUpdateAction(updateFn)

  const submitForm = async () => {
    const currentAnnotations = params.currentIndexes.reduce(
      (accum, index) => ({
        ...accum,
        [params.annotationKeys[index]]: params.annotationValues[index],
      }),
      {},
    )
    const newAnnotations = params.newIndexes.reduce(
      (accum, index) => ({
        ...accum,
        [params.newAnnotationKeys[index]]: params.newAnnotationValues[index],
      }),
      {},
    )
    const combinedAnnotations = {
      ...currentAnnotations,
      ...newAnnotations,
    }

    const ops = []
    ops.push({
      op: 'replace',
      path: '/metadata/annotations',
      value: combinedAnnotations,
    })

    const { success } = await update({
      ...entity,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) handleClose()
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const removeCurrentAnnotation = useCallback(
    (idx) => {
      updateParams({
        currentIndexes: without([idx], params.currentIndexes),
        annotationKeys: omit([idx], params.annotationKeys),
        annotationValues: omit([idx], params.annotationValues),
      })
    },
    [params, updateParams],
  )

  const removeNewAnnotation = useCallback(
    (idx) => {
      updateParams({
        newIndexes: without([idx], params.newIndexes),
        newAnnotationKeys: omit([idx], params.newAnnotationKeys),
        newAnnotationValues: omit([idx], params.newAnnotationValues),
      })
    },
    [params, updateParams],
  )

  const addNewAnnotation = useCallback(() => {
    const newIndex = params?.newIndexes?.length
      ? params.newIndexes[params.newIndexes.length - 1] + 1
      : 0
    updateParams({
      newIndexes: [...params.newIndexes, newIndex],
    })
  }, [params, updateParams])

  return (
    <ModalForm
      open={open}
      title={`Edit Annotations`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Annotations`}
    >
      <div className={classes.container}>
        <FormFieldSection title="Current Annotations">
          {!isNilOrEmpty(params?.currentIndexes) ? (
            <div className={classes.currentAnnotations}>
              {params?.currentIndexes?.map((idx) => (
                <div key={idx} className={classes.annotationRow}>
                  <div className={classes.annotationKey}>
                    <TextField
                      id={`currentKey${idx}`}
                      label="Key"
                      onChange={(value) =>
                        updateParams({
                          annotationKeys: {
                            ...params.annotationKeys,
                            [idx]: value,
                          },
                        })
                      }
                      value={params?.annotationKeys?.[idx]}
                      required
                    />
                  </div>
                  <span className={classes.annotationDash}>-</span>
                  <div className={classes.annotationValue}>
                    <TextField
                      id={`currentValue${idx}`}
                      label="Value"
                      onChange={(value) =>
                        updateParams({
                          annotationValues: {
                            ...params.annotationValues,
                            [idx]: value,
                          },
                        })
                      }
                      value={params?.annotationValues?.[idx]}
                      required
                    />
                  </div>
                  <FontAwesomeIcon
                    className={classes.minusIcon}
                    onClick={() => removeCurrentAnnotation(idx)}
                    size="xl"
                    solid
                  >
                    circle-minus
                  </FontAwesomeIcon>
                </div>
              ))}
            </div>
          ) : (
            <Text className={classes.noneText} variant="caption1">
              No Annotations
            </Text>
          )}
        </FormFieldSection>
        <FormFieldSection title="Add Annotations">
          <div className={classes.addAnnotations}>
            {params?.newIndexes?.map((idx) => (
              <div key={idx} className={classes.annotationRow}>
                <div className={classes.annotationKey}>
                  <TextField
                    id={`newKey${idx}`}
                    label="Key"
                    onChange={(value) =>
                      updateParams({
                        newAnnotationKeys: {
                          ...params.newAnnotationKeys,
                          [idx]: value,
                        },
                      })
                    }
                    value={params?.newAnnotationKeys?.[idx]}
                    required
                  />
                </div>
                <span className={classes.annotationDash}>-</span>
                <div className={classes.annotationValue}>
                  <TextField
                    id={`newValue${idx}`}
                    label="Value"
                    onChange={(value) =>
                      updateParams({
                        newAnnotationValues: {
                          ...params.newAnnotationValues,
                          [idx]: value,
                        },
                      })
                    }
                    value={params?.newAnnotationValues?.[idx]}
                    required
                  />
                </div>
                <FontAwesomeIcon
                  className={classes.minusIcon}
                  onClick={() => removeNewAnnotation(idx)}
                  size="xl"
                  solid
                >
                  circle-minus
                </FontAwesomeIcon>
              </div>
            ))}
            <div className={classes.addAnnotation} onClick={addNewAnnotation}>
              <FontAwesomeIcon className={classes.plusIcon} size="xl" solid>
                circle-plus
              </FontAwesomeIcon>
              <Text variant="body2">Add Annotation</Text>
            </div>
          </div>
        </FormFieldSection>
      </div>
    </ModalForm>
  )
}
